import React, { useRef } from "react"
import styled, { ThemeProvider } from "styled-components"
import { darkTheme } from "src/utils/themes"
import { ApprenticeshipHeader } from "src/components/Header"
import { rem } from "src/utils/styling"
import { PageSection } from "src/components/PageSection"
import Button from "src/components/Button"
import { HiChevronRight } from "react-icons/hi"
import Container from "src/components/Container"
import { LightFooter } from "src/components/Footer"
import { ReactTypeformEmbed } from "react-typeform-embed"
import HowItWorksCard from "src/components/apprenticeship/howitworks-card"
import Developer from "src/components/apprenticeship/developer"
import SocialEnterpriseSlide from "src/components/apprenticeship/social-enterprise-slider"

import { socialEnterpriseData } from "src/data/apprenticeData"
import OurClients from "src/components/apprenticeship/our-clients"
import MobileClients from "src/components/apprenticeship/mobile-clients"
import SEO from "src/components/seo"

const Section = styled(PageSection)`
  padding: ${rem(50)}rem 0;
  position: relative;
  background-color: ${props => (props.color ? props.color : "transparent")};
  @media screen and (min-width: 1100px) {
    padding: ${rem(10)}rem 0;
  }
  @media screen and (max-width: 1100px) {
    padding: ${rem(5)}rem 0;
  }
  @media screen and (max-width: 550px) {
    padding: ${rem(20)}rem 0;
  }
`

const SectionTitle = styled.h2`
  font-weight: 800;
  font-size: ${rem(24)}rem;
  color: ${props => (props.color ? props.color : "#000000")};
  text-align: center;
  align-self: center;

  > em {
    font-style: normal;
    color: #00b8c5;
  }

  @media screen and (min-width: 1100px) {
    font-size: ${rem(34)}rem;
  }
`

export const PageButton = styled(Button)`
  font-size: ${rem(18)}rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  @media screen and (max-width: 550px) {
    font-size: ${rem(15)}rem;
    padding: 0.4rem 1rem;
  }
  @media screen and (min-width: 1100px) {
    margin: 0;
  }
`

const CTAContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${rem(100)}rem ${rem(140)}rem;
  background: linear-gradient(283.7deg, #03162c 28.07%, #4d5357 185.43%);
  border-radius: 20px;
  width: 90%;
  margin: 0 auto -${rem(180)}rem;
  @media screen and (max-width: 500px) {
    padding: 60px;
  }
  @media screen and (min-width: 1100px) {
    width: 894px;
  }
`

const CTAContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const HWTitle = styled.h3`
  background: linear-gradient(260.25deg, #f093fb -10.66%, #f5576c 113%);
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  font-size: ${rem(24)}rem;
  padding: ${rem(20)}rem 0;
  text-align: center;
  @media screen and (min-width: 1100px) {
    font-size: ${rem(28)}rem;
  }
`

const HorizontalNavListSeparator = styled.li`
  padding: 0;
  margin: 0 ${rem(40)}rem;
  width: ${rem(3)}rem;
  height: ${rem(80)}rem;
  background: #fcb13f;
  display: block;
`

const TimelineVerticalSeparator = styled(HorizontalNavListSeparator)`
  height: ${rem(550)}rem;
  width: ${rem(2)}rem;
  background: linear-gradient(#fff, #aaa, #fff);
`

const HowItWorksContainer = styled.div`
  width: 100%;
  padding: 0 26px;
  margin: 0 0 ${rem(300)}rem;
  position: relative;
  box-sizing: border-box;

  @media screen and (min-width: 1100px) {
    flex-direction: column;
    width: 1100px;
    padding: 0 30px;
    margin: auto;
  }
  @media screen and (min-width: 1280px) {
    width: 1240px;
    margin: auto;
  }
  @media screen and (min-width: 1600px) {
    width: 1440px;
    margin: auto;
  }
`

const SocialEnterprise = ({ location }) => {
  const typeformRef = useRef()
  const onClickTypeform = () => {
    typeformRef.current && typeformRef.current.typeform.open()
  }
  return (
    <ThemeProvider theme={darkTheme}>
      <Container />
      <SEO
        title="Execute your highest impact projects"
        description="Work with curated Archimydes apprentices to execute your highest priority software development projects"
        location={location}
        image="social_enterprise_seo.png"
      />
      <ApprenticeshipHeader />
      <ReactTypeformEmbed
        popup
        autoOpen={false}
        hideHeaders
        hideFooter
        url="https://jfox813621.typeform.com/to/tsr4lW2z"
        ref={typeformRef}
      />
      <SocialEnterpriseSlide />
      <OurClients />
      <Section>
        <HowItWorksContainer>
          <SectionTitle>How it Works</SectionTitle>
          {socialEnterpriseData.map((item, idx) => (
            <HowItWorksCard
              key={`card-${idx}`}
              arrLen={socialEnterpriseData.length}
              cardIndex={idx}
              title={item.title}
              text={item.text}
              timelineTitle={item.timelineTitle}
              tiimelineText={item.tiimelineText}
              desktopImage={item.desktopImage}
              desktopImage2={item.desktopImage2}
              mobileImage={item.mobileImage}
            />
          ))}
        </HowItWorksContainer>
      </Section>
      <Developer />
      <MobileClients />
      <Section>
        <CTAContainer>
          <CTAContainerInner>
            <SectionTitle color={"#fff"} style={{ paddingBottom: 20 }}>
              Are you ready to engage our <br />
              Apprentices?
            </SectionTitle>
            <div onClick={onClickTypeform}>
              <PageButton type="solid">
                Let’s go
                <HiChevronRight size={22} />
              </PageButton>
            </div>
          </CTAContainerInner>
        </CTAContainer>
      </Section>
      <LightFooter paddingTop={280} />
    </ThemeProvider>
  )
}

export default SocialEnterprise
